<template>
  <div>
    <h4 class="mb-0 text-body">
      {{ payable.displayName }}
      <svgicon
        :id="payable.displayType + '-' + _uid"
        :fill="false"
        icon="question-mark-circle"
        width="1rem"
        height="1rem"
      />
      <b-tooltip
        class="tooltip"
        placement="right"
        triggers="hover click"
        :target="payable.displayType + '-' + _uid"
      >
        {{ toolTipText }}
      </b-tooltip>
    </h4>
    <div
      v-if="payable.description"
      :class="textClasses.description"
      class="preserve-newlines small"
    >{{ payable.description }}</div>
  </div>
</template>

<script>
export default {
  computed: {
    textClasses () {
      return {
        displayName: `text-${this.darkDisplay ? 'dark' : 'body'}`,
        description: this.smallDescription ? 'small' : '',
      }
    },
    toolTipText () {
      return this.payable.customParameters.service_fee_amount > 0
        ? this.$t('rex.' + this.payable.displayType + '.tooltip', { serviceFeeAmount: this.payable.customParameters.service_fee_amount })
        : this.$t('rex.' + this.payable.displayType + '.tooltip-no-fee')
    },
  },
  props: {
    payable: {
      type: Object,
      required: true,
    },
    variation: {
      type: String,
      default: '',
    },
    darkDisplay: {
      type: Boolean,
      default: false,
    },
    smallDescription: {
      type: Boolean,
      default: false,
    },
  },
}
</script>
