<template>
  <!-- Here, we're *intentionally* overriding the default container margins -->
  <div
    :id="payable.sanitizedPath"
    class="container-fluid"
    :class="`${colLimit} bg-white rounded px-4 px-xxl-8 py-4 mb-2`"
  >
    <!-- Display the default payable alert if we haven't gotten an extra item error -->
    <PayableAlert
      v-if="!extraItemSearchError"
      :payable="payable"
    />

    <!-- If we got an extra item error, display the fallback alert - this is the full message if we weren't trying to do anything fancy -->
    <Alert
      v-else-if="extraItemSearchError"
      :dismissible="false"
      :show-icon="false"
      variant="warning"
      class="mb-3 d-really-inline-block"
    >
      <div v-dompurify-html="payable.rexHubCustomParameters.extraItemsErrorFallback.html_display" />
    </Alert>

    <slot
      name="cornerIndicator"
      :payable="payable"
    />

    <div class="row">
      <div class="col-12 col-sm-5 col-xxl-6 d-block">
        <!--
          Vehicle renewals with children/payment choices can be displayed as a
          single row with a dropdown for children (as variants of the primary).

          Don't show any plate fees in the description because we show a full
          breakdown here.
        -->
        <PayableDescription
          ref="payableDescription"
          :payable="selectedPayable"
          :large-title="largeTitle"
          :show-plate-fees="false"
          @change-payable="selectedPayable = $event"
          @change-plate="$emit('change-plate', $event)"
          @change-rental-park-status="$emit('change-rental-park-status', $event)"
          @update-insurance="$emit('update-insurance', $event)"
        />
      </div>

      <slot name="amount">
        <div class="col-12 col-sm-7 col-xxl-6 text-right border-xxl-left">

          <div
            v-if="selectedPayable.displayAmount"
            class="fee-grid"
          >
            <div><!-- Spacer for css grid --></div>
            <div class="amount mb-1 mb-md-2">
              {{ selectedPayable.selectedRExFees?.totalFees.display }}
            </div>
            <template v-if="selectedPayable.selectedRExFees?.delinquentFee.number > 0">
              <div class="sub-fee">
                {{ $t('rex.item.delinquent_fee') }}
              </div>
              <div class="sub-fee">
                {{ selectedPayable.selectedRExFees?.delinquentFee.display }}
              </div>
            </template>

            <!-- If there's a plate change then display a fee breakdown -->
            <template v-if="selectedPayable.rexHubCustomParameters.plateChange">
              <template v-if="selectedPayable.selectedPlateChangeFees.annualFee.number > 0">
                <div class="sub-fee">
                  {{ $t('rex.plate_change.fee_breakdown.annual_fee') }} {{ selectedPayable.selectedPlateChangeFees?.multiplier }}
                </div>
                <div class="sub-fee">
                  {{ selectedPayable.selectedPlateChangeFees?.annualFee.display }}
                </div>
              </template>

              <template v-if="selectedPayable.selectedPlateChangeFees.annualProcessingFee.number > 0">
                <div class="sub-fee">
                  {{ $t('rex.plate_change.fee_breakdown.annual_processing_fee') }} {{ selectedPayable.selectedPlateChangeFees?.multiplier }}
                </div>
                <div class="sub-fee">
                  {{ selectedPayable.selectedPlateChangeFees?.annualProcessingFee.display }}
                </div>
              </template>
              <template v-if="selectedPayable.selectedPlateChangeFees.newPlateReplacementFee.number > 0">
                <div class="sub-fee">
                  {{ $t('rex.plate_change.fee_breakdown.plate_replacement_fee') }}
                </div>
                <div class="sub-fee">
                  {{ selectedPayable.selectedPlateChangeFees?.newPlateReplacementFee.display }}
                </div>
              </template>
            </template>
          </div>
          <div v-if="selectedPayable.customParameters && selectedPayable.customParameters.effective_date">
            {{ $t("effective.date") }} <b>{{ selectedPayable.customParameters.effective_date }}</b>
          </div>
          <div v-if="selectedPayable.customParameters && selectedPayable.customParameters.receipt_number">
            {{ $t("receipt.num") }} <b>{{ selectedPayable.customParameters.receipt_number }}</b>
          </div>

        </div>
      </slot>

    </div>

    <slot
      name="payableAncillary"
      :payable="selectedPayable"
    />

    <!--
      Display extra items attached to this renewal.
    -->
    <div v-if="$wait.is(extraSearchEventId)">
      <div class="col-12 mt-3 d-flex border-top p-0">
        <div class="col-12 d-block pt-2">
          <LoadingBars />
        </div>
      </div>
    </div>
    <div v-else-if="extraItems.length > 0 && extraItemSearchError === undefined">
      <b-row
        v-for="extraItem in extraItems"
        :key="extraItem.path"
      >
        <div class="col-12 mt-3 d-flex border-top p-0">
          <div class="col-6 col-sm-5 col-xxl-6 d-block pt-2">
            <div>
              <!-- This functionality can be expanded when we offer more than just Tolls as extra items -->
              <TollViolationDescription :payable="extraItem" />
            </div>
          </div>
          <div class="col-6 col-sm-7 col-xxl-6 text-right pt-2">
            <span class="amount">{{ `$${extraItem.displayAmount}` }}</span>
          </div>
        </div>
      </b-row>
    </div>

    <!--
      Display the subtotal for this renewal and any of its extra items.
    -->
    <b-row
      v-if="displaySubtotal"
    >
      <div
        class="pt-1 pt-md-2 col-12 mt-3 d-flex justify-content-end border-top"
      >
        <div class="pt-1 pt-md-2 subtotal-grid">
          <span class="pr-2">{{ $t('payments.subtotal') }}</span>
          <span class="amount">
            ${{ selectedPayable.rexSubtotal(extraItems) }}
          </span>
        </div>
      </div>
    </b-row>

    <b-row>
      <div class="col-12 mt-3 d-flex justify-content-end">
        <slot
          name="payableActions"
          :payable="selectedPayable"
          :validate="() => $refs.payableDescription.validate()"
          :disable-add-to-cart="extraItemSearchError ? true : false"
          :extra-payables="extraItems"
        />
      </div>
    </b-row>

    <PayableNotice
      :payable="selectedPayable"
    />

  </div>
</template>

<script>
import PayableDescription from '../description/PayableDescription.vue'
import PayableAlert from '@grantstreet/psc-vue/components/PayableAlert.vue'
import Alert from '@grantstreet/psc-vue/components/Alert.vue'
import PayableNotice from '@grantstreet/psc-vue/components/PayableNotice.vue'

import { formatDate } from '@grantstreet/psc-js/utils/date.js'
import store from '../../store/index.ts'
import LoadingBars from '@grantstreet/loaders-vue/LoadingBars.vue'
import TollViolationDescription from '../description/display-types/rex-vehicle-registration/TollViolationDescription.vue'

export default {
  components: {
    PayableDescription,
    PayableAlert,
    PayableNotice,
    LoadingBars,
    Alert,
    TollViolationDescription,
  },
  emits: [
    'change-plate',
    'change-rental-park-status',
    'update-insurance',
  ],
  props: {
    payable: {
      type: Object,
      required: true,
    },
    titleClass: {
      type: String,
      default: '',
    },
    colLimit: {
      type: String,
      default: '',
    },
    largeTitle: {
      type: Boolean,
      default: false,
    },
  },
  beforeMount () {
    this.searchExtraPayables()
  },

  data () {
    return {
      selectedPayable: this.payable,
      extraItems: [],
      extraItemSearchError: null,
    }
  },

  computed: {
    // Determine if we need to display a fee breakdown or just the renewal total.
    // If there's a plate change or other fee then use this method to display the base renewal
    // amount for this payable and the associated fees. Otherwise display the full displayAmount as normal.
    displayFeeBreakdown () {
      return this.selectedPayable.isPayable &&
        (this.selectedPayable.rexHubCustomParameters.plateChange || this.selectedPayable.selectedRExFees?.delinquentFee.number > 0 ||
        (this.extraItems.length > 0 && this.extraItemSearchError === undefined))
    },

    displaySubtotal () {
      return this.selectedPayable.isPayable && this.displayFeeBreakdown
    },

    extraSearchEventId () {
      return 'searchingExtras-' + this.payable.uniqueId
    },
  },

  methods: {
    formatDate,

    // For the parent to change this value manually when search results change
    setRExIsInRentalPark (isInRentalPark) {
      return this.$refs.payableDescription.setRExIsInRentalPark(isInRentalPark)
    },

    // For the parent to change this value manually when search results change
    setRExRenewalDuration (renewalDuration) {
      return this.$refs.payableDescription.setRExRenewalDuration(renewalDuration)
    },

    async searchExtraPayables () {
      let results
      let error

      // If we don't have items, don't pretend like we will search for them
      // If we have some stops, don't search for tolls until we potentially clear those.
      const extraItems = this.selectedPayable.rexHubCustomParameters.extraItems
      if (extraItems.length === 0 || this.selectedPayable.isPayableMessageDisplay) {
        return
      }

      this.$wait.start(this.extraSearchEventId)
      const extraPayables = []
      for (const extraItem of extraItems) {
        try {
          const query = extraItem.query

          const options = extraItem.options || {};
          ({ payables: results } = await store.dispatch('searchPayables', {
            payablesAdaptor: extraItem.adaptor,
            data: {
              query,
              options,
            },
            language: this.$i18n.locale,
          }))
        }
        catch (error_) {
          error = error_.response?.data?.displayMessage || this.$t('api.error')
        }

        if (results?.[0]) {
          extraPayables.push(results[0])
        }
      }

      this.extraItemSearchError = error

      // If we got any errors, don't show extra items.
      // We want to just display our fallback error
      if (this.extraItemSearchError) {
        this.extraItems = []
      }
      else {
        this.extraItems = extraPayables
      }
      this.$wait.end(this.extraSearchEventId)
    },

  },
}
</script>

<style lang="scss" scoped>

.amount {
  font-size: $font-size-big;
  font-weight: 100;
}

.fee-grid {
  display: grid;
  grid-template-columns: 1fr auto;
  grid-column-gap: 1rem;
  justify-content: end;
  align-items: end;

  .sub-fee {
    font-size: 1rem;

    @include media-breakpoint-up(lg) {
      font-size: $h4-font-size;
    }
  }

  .subtotal-grid {
    display: grid;
    grid-column-start: 1;
    grid-column-end: 3;
    // Use subgrid when we can
    grid-template-columns: inherit;
    grid-column-gap: 1rem;
    align-items: baseline;
  }
}

</style>
